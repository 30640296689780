export const CAPTCHA_KEY = "6LcAgikaAAAAAOTs9Oa8h6Qi_p0-L92W_yxnp0_9";

export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DATE_FORMAT = "YYYY-MM-DD"
export const TIME_FORMAT = "HH:mm";

export const ORDER_DIRECTION = {
    ASC: 1,
    DESC: 2
}

export const BROADCAST_CHANNEL_NAME = "RT-BRODCAST_CHANNEL";

export const PROJECT_TYPE = {
    AGENT_SYSTEM: 1 << 0,
    RETAIL_SYSTEM: 1 << 1,
    TERMINAL: 1 << 2
}

export const USER_STATE = {
    IN_PROGRESS: 1,
    ACTIVE: 2,
    BLOCKED: 4,
    EXPIRED: 8
}

export const PROJECT_PROVIDER_TYPE = {
    SPORTBOOK: 1,
    VIRTUAL_SPORTS: 2,
    GOLDEN_RACE: 4
}

export const TRANSACTION_TYPE = {
    BET: 1,
    CANCEL_BET: 2,
    PAIDOUT: 3,
    WON: 4,
    ROLLBACK: 5,
    REJECT_BET: 6,
    RECALCULATE: 7,
    PLAYER_DEPOSIT: 61,
    PLAYER_WITHDRAWAL: 62,
    BETSHOP_MANAGER_DEPOSIT_CASHIER: 71,
    BETSHOP_MANAGER_WITHDRAW_CASHIER: 72,
    BETSHOP_CASHIER_HANDOVER_CASHIER: 101,
    BETSHOP_LIMIT_AUTO_ADJUSTMENT: 111,
    BETSHOP_LIMIT_MANUAL_ADJUSTMENT: 112,
}

export const WALLET_OWNER_TYPE = {
    AGENT: 1 << 0,
    BETSHOP: 1 << 1,
    CASHIER: 1 << 2,
    SUPER_AGENT: 1 << 3,
    AGENT_PLAYER: 1 << 4,
    PLAYER: 1 << 5,
    VOUCHER: 1 << 6,
}

export const WALLET_TYPE = {
    BALANCE: 1 << 0,
    CREDIT_LINE: 1 << 1,
    CREDIT: 1 << 2,
    LIMIT: 1 << 3
}

export const PRODUCT_TYPE = {
    ONLINE: 1,
    RETAIL: 2
}

export const PLAYER_TRANSACTION_TYPE = {
    DEPOSIT: 1,
    WITHDRAW: 2
}

export const PLAYER_STATE = {
    ACTIVE: 1 << 0,
    BLOCKED: 1 << 1,
    PARTIALY_BLOCKED: 1 << 2,
    UNKNOWN: 1 << 3
}

export const PLAYER_FIND_BY = {
    ID: 1,
    USERNAME: 2,
    EMAIL: 3,
    MOBILE: 4,
    DOCUMENT: 5
}

export const ENVIRONMENT_TYPE = {
    AGENT_SYSTEM: 1,
    RETAIL: 2
}

export const BETSHOP_ACCESS_TYPE = {
    PLAYER_CREATE: 1 << 0,
    PAYMENT: 1 << 1,
    BETS: 1 << 2
}

export const DATE_PICKER_RANGES = {
    TODAY: "today",
    YESTERDAY: "yesterday",
    THIS_MONTH: "thisMonth",
    LAST_MONTH: "lastMonth",
    LAST_3_MONTH: "last3Month",
    YTD: "ytd"
}

export const BETSHOP_BET_TYPE = {
    ANONYMOUS: 1,
    NON_ANONYMOUS: 2
}

export const EXPORT_TYPE = {
    CSV: 1,
    PDF: 2
}

export const SIGNALR_CONNECTION_TYPES = {
    ADMIN: 0,
    JOB: 1,
    CASHIER: 2,
}

export const BETSLIP_FILTER_TYPE = {
    BET_TIME: 1,
    CALCULATION_TIME: 2,
    PAYOUT_TIME: 3
}
