import { binaryToFlags } from "./common";

import {BETSHOP_ACCESS_TYPE, PROJECT_TYPE} from "constants/common.constants";

/** Check, if cashier has payment access
     * @function
     * @param {object} userInfo - user info
     * @returns {bool}
 */
export const hasPaymentAccess = userInfo => {
    if(!userInfo) return false;
    return binaryToFlags(Object.values(BETSHOP_ACCESS_TYPE), userInfo.additionalAccess).includes(BETSHOP_ACCESS_TYPE.PAYMENT)
}

/** Check, if cashier has bets access
     * @function
     * @param {object} userInfo - user info
     * @returns {bool}
 */
export const hasBetsAccess = userInfo => {
    if(!userInfo) return false;
    return binaryToFlags(Object.values(BETSHOP_ACCESS_TYPE), userInfo.additionalAccess).includes(BETSHOP_ACCESS_TYPE.BETS)
}

/** Check, if cashier has player create access
     * @function
     * @param {object} userInfo - user info
     * @returns {bool}
 */
export const hasPlayerCreateAccess = userInfo => {
    if(!userInfo) return false;
    return binaryToFlags(Object.values(BETSHOP_ACCESS_TYPE), userInfo.additionalAccess).includes(BETSHOP_ACCESS_TYPE.PLAYER_CREATE)
}

export const isTerminalProject = projectType => {
    return (projectType & PROJECT_TYPE.TERMINAL) > 0;
}
