import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Profile from 'components/common/profile';

import { changePopupVisibility } from 'store/actions/dashboard/common.action';

import { POPUP_TYPE } from 'constants/popup.constants';
import PATHS from 'constants/path.constants';

import MirrorImg from "assets/images/mirror.png"
import BarcodeImg from "assets/images/barcode.png"
import {PROJECT_PROVIDER_TYPE} from "constants/common.constants";
import Games from "components/cashier/header/actions/components/games";

/** Actions section in header */
const Actions = ({
    changePopupVisibility,
    provider,
    providers
}) => {

    const { t } = useTranslation();

    /** Function to open mirror ticket window
		 * @function
		 * @memberOf Actions
    */
    const openMirorTicket = () => {
        window.open(PATHS.MIRROR, "popup", "height=" + window.screen.availHeight + ",width=" + window.screen.availWidth);
    }

    return (
        <div className='rt--cashier-header-actions rt--flex rt--align-center rt--pt-12 rt--pb-12'>
            <div className='rt--cashier-header-actions-inner rt--flex rt--align-center rt--justify-end'>
                <div
                    className='rt--flex rt--align-center rt--justify-center rt--cashier-header-action rt--cursor-pointer'
                    onClick={openMirorTicket}
                    title={t("common.mirrorTicket")}
                >

                    <img alt="mirror" src={MirrorImg} />
                </div>
                <div
                    className='rt--flex rt--align-center rt--justify-center rt--cashier-header-action rt--ml-24 rt--cursor-pointer'
                    onClick={() => changePopupVisibility({ key: POPUP_TYPE.CHECK_TICKET })}
                    title={t("common.checkTicket")}
                >
                    <img alt="barcode" src={BarcodeImg} />
                </div>

                {provider === PROJECT_PROVIDER_TYPE.GOLDEN_RACE && (
                    <Games provider={provider} providers={providers} />
                )}
                <Profile/>
            </div>
        </div>

    )
}

/** Actions propTypes
 * PropTypes
 */
Actions.propTypes = {
    /** Redux action to open popup */
    changePopupVisibility: PropTypes.func,
    provider: PropTypes.number,
    providers: PropTypes.arrayOf(PropTypes.object)
}

const mapStateToProps = state => ({
    provider: state.providers.provider,
    providers: state.providers.providers,
})

const mapDispatchToProps = dispatch => (
    {
        changePopupVisibility: (data) => {
            dispatch(changePopupVisibility(data))
        }
    }
)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Actions
);
