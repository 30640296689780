import React, {useState} from 'react';
import {POPUP_SIZE, POPUP_TYPE} from "constants/popup.constants";
import {Button, Form, Input, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {hasDotAndDotIsAtTheEnd, printElement} from "utils/common";
import {INTEGER_AND_DECIMAL_NUMBERS_REGEX} from "constants/regex.constants";
import {WALLET_OWNER_TYPE} from "constants/common.constants";
import FavoriteAmounts from "components/common/favoriteAmounts";
import {connect} from "react-redux";
import {createVoucher} from "components/common/popup/voucherCreate/api";
import {changePopupVisibility} from "store/actions/dashboard/common.action";
import VoucherTicket from "components/common/tickets/voucher";
import moment from "moment";

const VoucherCreatePopup = ({ userInfo, handleCloseModal, changePopupVisibility }) => {
    const [voucher, setVoucher ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ amount, setAmount ] = useState("");
    const [ errors, setErrors ] = useState({
        minAmount: false,
        maxAmount: false
    })

    const { t } = useTranslation();

    const currency = userInfo?.currencies?.[0]?.code ?? null;

    const min = 100;
    const max = 10000;

    const validateAmount = (amount) => {
        let isValid = true;

        if (amount < min) {
            isValid = false;

            setErrors(state => ({...state, minAmount: true }));
        } else {
            setErrors(state => ({...state, minAmount: false }));
        }

        if (amount > max) {
            isValid = false;

            setErrors(state => ({...state, maxAmount: true }));
        } else {
            setErrors(state => ({...state, maxAmount: false }));
        }

        return isValid;
    }

    const handleAmountChange = value => {
        if (value === '' || (hasDotAndDotIsAtTheEnd(value)) || INTEGER_AND_DECIMAL_NUMBERS_REGEX.test(value)) {
            // validateAmount(Number(value) || 0);

            setAmount(value)
        }
    }

    const handleFavAmountClick = value => {
        const currentValue = amount ?? 0;

        setAmount(Number(currentValue) + value);
    }

    const handleCreate = () => {
        setLoading(true);

        createVoucher({ amount, timeZone: new Date().getTimezoneOffset() / 60 * -1 })
            .then(({data: {value}}) => {
                if (value.voucher) {
                    setVoucher(value.voucher);

                    setTimeout(() => {
                        printElement(value.voucher.id, () => {
                            changePopupVisibility({ key: POPUP_TYPE.CLOSE });
                        });
                    }, 100);
                } else {
                    changePopupVisibility({ key: POPUP_TYPE.FAILED_TRANSFER, data: value.walletActions.wallets });
                }
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <Modal
            className="rt--modal rt--modal-withdrawals"
            title={
                <div className="rt--flex rt--justify-between rt--align-center">
                    <span className="rt--title rt--font-normal rt--font-regular">
                        {t("common.createVoucher")}
                    </span>
                    <i
                        className="icon-close rt--font-bigest rt--cursor-pointer"
                        onClick={() => handleCloseModal()}
                    />
                </div>
            }
            visible={true}
            closable={false}
            maskClosable={false}
            getContainer={() => document.getElementById("rt--modal-root")}
            width={POPUP_SIZE.SMALL}
            centered
            footer={null}
        >
            {voucher && <div style={{ display: 'none' }}><VoucherTicket voucher={voucher} /></div>}
            <div className={'rt--modal-form-item rt--flex rt--flex-col rt--mb-8'}>
                <span className='rt--title rt--font-medium rt--font-normal rt--mb-8'>
                    {t("common.amount")}
                </span>

                <Input
                    className='rt--input'
                    placeholder={t("common.enter") + " " + t("common.amount")}
                    value={amount}
                    onChange={e => handleAmountChange(e.target.value)}
                />
            </div>

            <FavoriteAmounts
                currencyCode={currency}
                handleClick={handleFavAmountClick}
                walletUserType={WALLET_OWNER_TYPE.VOUCHER}
            />

            {/*<div className="rt--voucher-create-validation">*/}
            {/*    <span className={errors.minAmount && "rt--voucher-create-validation-error"}>*{t("common.minAmountIs")}</span>*/}
            {/*    <span className={errors.maxAmount && "rt--voucher-create-validation-error"}>*{t("common.maxAmountIs")}</span>*/}
            {/*</div>*/}

            <div className="rt--flex rt--cg-16 rt--mt-16">
                <Button className="rt--button rt--button-secondary rt--flex-equal" onClick={handleCloseModal}>{t("common.cancel")}</Button>
                <Button
                    className="rt--button rt--button-primary rt--flex-equal"
                    loading={loading}
                    onClick={handleCreate}
                    disabled={!amount}
                >
                    {t("common.create")}
                </Button>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    userInfo: state.profile.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
    changePopupVisibility: (data) => {
        dispatch(changePopupVisibility(data));
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(VoucherCreatePopup);
