import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { Modal, Input } from 'antd';

import Table from 'components/common/table';
import Checkbox from 'components/common/checkbox';
import Loader from 'components/common/loader';

import { payout } from 'store/actions/dashboard/ticket.action';

import { BET_STATE, BET_TYPE } from 'constants/bets.constants';
import { BETSHOP_BET_TYPE, PROJECT_PROVIDER_TYPE, DATE_TIME_FORMAT } from 'constants/common.constants';
import { POPUP_SIZE } from "constants/popup.constants";

import { getBetStateText } from "utils/common";

import useFormat from 'hooks/useFormat';

import ticketInfoType from 'types/ticketInfo.type';
import userInfoType from 'types/userInfo.type';

/** Check Ticket Popup Ticket View Component */
const Ticket = ({
    ticketInfo,
    onCancel,
    payout,
    isPayoutLoading,
    userInfo
}) => {
    const { t } = useTranslation();

    const { formatAmount } = useFormat();

	const canPlaceAnonymousBets = BETSHOP_BET_TYPE.ANONYMOUS === userInfo.bettingType;

    const currency = userInfo?.currencies?.[0] ?? null;

    /** Selected ticket ids */
    const [ticketIds, setTicketIds] = useState([]);

    /** Flag to show/hide pin code popup */
    const [showPinCode, setShowPinCode] = useState(false);
    /** Pin code field value */
    const [pinCode, setPinCode] = useState("");

    /** Get Provider */
    const provider = ticketInfo[0]?.provider;

    /** Has Golden Race Game */
    const hasGoldenRaceBet = provider === PROJECT_PROVIDER_TYPE.GOLDEN_RACE;

    /** Is there a ticket which can be paidout */
    const hasTicketToPayout = ticketInfo.some(ticket => !ticket.paidoutTime && [BET_STATE.WON, BET_STATE.SEMIWON, BET_STATE.SEMILOST, BET_STATE.RETURN].includes(ticket.status));

    /** Is all tickets selected */
    const areAllSelected = ticketInfo.filter(ticket => !ticket.paidoutTime && [BET_STATE.WON, BET_STATE.SEMIWON, BET_STATE.SEMILOST, BET_STATE.RETURN].includes(ticket.status)).length === ticketIds.length

    /** Function, to check/uncheck rows
         * @function
         * @memberOf Ticket
    */
    const toggleAll = () => {
        let ids = [];

        if (!areAllSelected) {
            ids = ticketInfo.filter(ticket => !ticket.paidoutTime && [BET_STATE.WON, BET_STATE.SEMIWON, BET_STATE.SEMILOST, BET_STATE.RETURN].includes(ticket.status)).map(ticket => ticket.id)
        }

        setTicketIds(ids);
    }

    /** Table Columns */
    const columns = [
        {
            key: "id",
            title: t("common.betId"),
        },
        {
            key: "betType",
            title: t("common.betType"),
            render: value => <span className='rt--title rt--font-normal rt--font-regular rt--font-capitalize'>{
                value === BET_TYPE.SINGLE ? t('common.single') : value === BET_TYPE.MULTI ? t('common.multi') : t('common.system')}</span>
        },
        {
            key: "factor",
            title: t("common.odds"),
        },
        {
            key: "amount",
            title: t("common.stake"),
            render: value => <span className='rt--title rt--font-normal rt--font-regular'>{formatAmount(value, currency?.code)}</span>
        },
        {
            key: "status",
            title: t("common.status"),
            render: value => (
                <div className='rt--flex rt--align-center rt--status' data-type={[BET_STATE.WON, BET_STATE.SEMIWON, BET_STATE.SEMILOST, BET_STATE.RETURN].includes(value) ? "success" : value === BET_STATE.PENDING ? "pending" : "error"}>
                    <span className='rt--title rt--font-normal rt--font-regular rt--font-capitalize'>{getBetStateText(value)}</span>
                </div>
            ),
        },
        {
            key: "possibleWin",
            title: t("common.possibleWin"),
            render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? formatAmount(value, currency?.code) : "-"}</span>
        },
        {
            key: "winning",
            title: t("common.win"),
            render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? formatAmount(value, currency?.code) : "-"}</span>
        },
		  ...(!canPlaceAnonymousBets ? [{
					key: 'playerId',
					title: t('common.playerId'),
					render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value || '-'}</span>
			}] : []),
		  ...(!canPlaceAnonymousBets ? [{
				key: 'playerUserName',
				title: t('common.playerUsername'),
				render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value || '-'}</span>
		  }] : []),
        {
            key: "paidoutTime",
            title: t("common.payoutAt"),
            render: value => <span title={value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : ""} className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>
        },
        {
            key: "paidoutBy",
            title: t("common.payoutBy"),
            render: value => <span title={value || ""} className='rt--title rt--font-normal rt--font-regular'>{value || "-"}</span>
        }
    ]

    /** Add checkboxes to table rows */
    if (hasTicketToPayout && !hasGoldenRaceBet) {
        columns.unshift({
            key: "payout",
            title: "",
            render: (_, record) => !record.paidoutTime && [BET_STATE.WON, BET_STATE.SEMIWON, BET_STATE.SEMILOST, BET_STATE.RETURN].includes(record.status) ? (
                <Checkbox
                    checked={ticketIds.includes(record.id)}
                    onChange={value => {
                        if (value) {
                            setTicketIds(ticketIds.concat(record.id))
                        } else {
                            setTicketIds(ticketIds.filter(id => id !== record.id))
                        }
                    }}
                />
            ) : <Fragment />
        })
    }

    /** Function, to call on payout button click
         * @function
         * @memberOf Ticket
    */
    const handlePayout = () => {
        let ids = [...ticketIds];

        if(hasGoldenRaceBet){
            const externalBetSlipId = ticketInfo[0]?.externalBetSlipId;
            ids = [externalBetSlipId]
        }

        if (ids.length > 0) {

            const hasSportBet = ticketInfo.filter(ticket => ids.includes(ticket.id)).some(ticket => ticket.provider === PROJECT_PROVIDER_TYPE.SPORTBOOK)

            if (hasSportBet) {
                setShowPinCode(true)
            } else {
                const requestBody = {
                    ids: ids,
                    betTime: ticketInfo[0]?.betTime ?? null,
                    provider: ticketInfo[0]?.provider ?? null,
                    betType: ticketInfo[0]?.betType ?? null
                };

                if(hasGoldenRaceBet){
                    requestBody.partnerId = ticketInfo[0]?.externalPartnerId;
                }

                payout(requestBody, () => {
                    setTicketIds([]);
                })
            }
        }
    }

    /** Function, to call on ok button click on pin code modal
         * @function
         * @memberOf Ticket
    */
    const handlePinCode = () => {
        payout({
            ids: ticketIds,
            betTime: ticketInfo[0]?.betTime ?? null,
            provider: ticketInfo[0]?.provider ?? null,
            betType: ticketInfo[0]?.betType ?? null,
            pinCode: pinCode
        }, () => {
            setTicketIds([]);
        })
        setShowPinCode(false);
    }

    /** Reset pin code value */
    useState(() => {
        if (!showPinCode) {
            setPinCode("")
        }
    }, [showPinCode])

    const isPayoutButtonActive = () => {
        if(hasGoldenRaceBet){
            return ticketInfo.some(ticket => ticket.paidoutTime === null)
        } else {
            return ticketIds.length >= 0
        }
    }

    return (
        <div className='rt--modal-check-ticket-view'>
            <div className='rt--modal-check-ticket-view-header rt--flex rt--align-center rt--justify-between'>
                <div className='rt--flex rt--align-center'>
                    <i
                        className='icon-left rt--font-bigest rt--mr-4 rt--cursor-pointer'
                        onClick={onCancel}
                    />
                    <div className='rt--flex rt--flex-col'>
                        <b className='rt--title rt--font-big rt--font-regular'>
                            {
                                `${t("common.ticketId")} ${ticketInfo[0]?.externalBetSlipId ?? ""}`
                            }
                        </b>
                        <span className='rt--title rt--font-normal rt--font-regular rt--pt-4 rt--modal-check-ticket-view-header-date'>
                            {
                                moment(ticketInfo[0]?.betTime).format(DATE_TIME_FORMAT)
                            }
                        </span>
                    </div>
                </div>
                {
                    hasTicketToPayout && (
                        <div className='rt--flex rt--justify-end rt--align-center'>
                            {
                                !hasGoldenRaceBet && (
                                    <button
                                        className="rt--button rt--button-text rt--ml-16 rt--mr-16"
                                        onClick={toggleAll}
                                    >
                                        <span className='rt--title rt--font-medium rt--font-normal'>{areAllSelected ? t('common.deselectAll') : t('common.selectAll')}</span>
                                    </button>
                                )
                            }
                            
                            <button
                                className={
                                    "rt--button rt--button-complimentary" + 
                                    (!isPayoutButtonActive() ? " rt--button-disabled" : "")
                                }
                                onClick={handlePayout}
                            >
                                <span className='rt--title rt--font-medium rt--font-normal'>{t('common.payout')}</span>
                            </button>
                        </div>
                    )
                }

            </div>
            <div className={'rt--modal-check-ticket-view-content rt--mt-24' + (hasTicketToPayout ? " rt--modal-check-ticket-view-content-payout" : "")}>
                <Table
                    name="tickets"
                    columns={columns}
                    data={ticketInfo}
                    isLoading={false}
                    actions={[]}
                />
            </div>

            {
                showPinCode && (
                    <Modal
                        className='rt--modal'
                        title={(
                            <div className='rt--flex rt--justify-between rt--align-center'>
                                <span className='rt--title rt--font-normal rt--font-regular'>{`${t("common.enter")} ${t("common.pinCode")}`}</span>
                                <i className='icon-close rt--font-bigest rt--cursor-pointer' onClick={() => setShowPinCode(false)} />
                            </div>
                        )}
                        visible={true}
                        onCancel={() => setShowPinCode(false)}
                        closable={false}
                        maskClosable={false}
                        onOk={() => handlePinCode()}
                        cancelButtonProps={{ className: 'rt--button-secondary' }}
                        okButtonProps={{ className: 'rt--button-primary' }}
                        cancelText={t('common.cancel')}
                        okText={t('common.done')}
                        getContainer={() => document.getElementById('rt--modal-root')}
                        width={POPUP_SIZE.SMALLEST}
                        centered
                    >
                        <div className='rt--modal-form-item rt--flex rt--flex-col'>
                            <span className='rt--title rt--font-medium rt--font-normal rt--mb-8'>{t("common.pinCode")}</span>
                            <div className='rt--flex rt--align-center'>
                                <Input
                                    maxLength={10}
                                    className='rt--input'
                                    placeholder={`${t("common.enter")} ${t("common.pinCode")}`}
                                    onChange={e => setPinCode(e.target.value)}
                                    value={pinCode}
                                />
                            </div>
                        </div>

                    </Modal>
                )
            }

            {
                isPayoutLoading && (<Loader fixed={true} />)
            }

        </div>
    )
}

/** Ticket propTypes
    * PropTypes
*/
Ticket.propTypes = {
    /** Function to call on ticket view close */
    onCancel: PropTypes.func,
    /** Redux state property, ticket info */
    ticketInfo: PropTypes.arrayOf(ticketInfoType),
    /** Redux state property, is true when payout actino is in process  */
    isPayoutLoading: PropTypes.bool,
    /** Redux action to payout */
    payout: PropTypes.func,
    /** Redux state property, current user info */
    userInfo: userInfoType
}

const mapDispatchToProps = dispatch => (
    {
        payout: (info, onSuccess) => {
            dispatch(payout(info, onSuccess))
        }
    }
)

const mapStateToProps = state => {
    return {
        isPayoutLoading: state.ticket.isPayoutLoading,
        userInfo: state.profile.userInfo
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Ticket
);
