import React from 'react';
import {Button} from "antd";
import {changePopupVisibility} from "store/actions/dashboard/common.action";
import {connect} from "react-redux";
import {POPUP_TYPE} from "constants/popup.constants";
import {useTranslation} from "react-i18next";

const Voucher = ({ changePopupVisibility }) => {
    const { t } = useTranslation();

    const handleOpenModal = () => {
        changePopupVisibility({ key: POPUP_TYPE.VOUCHER_CREATE });
    }

    return (
        <div className="rt--cashier-header-voucher">
            <Button
                className="rt--button rt--button-complimentary"
                onClick={handleOpenModal}
            >
                {t("common.voucher")}
            </Button>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    changePopupVisibility: (data) => {
        dispatch(changePopupVisibility(data));
    },
});

export default connect(null, mapDispatchToProps)(Voucher);
