import axios from "axios";
import ApiUrls from "constants/api.constants";
import Methods from "constants/httpMethods.constants";

export const createVoucher = (data) => {
    return axios({
        url: ApiUrls.CREATE_VOUCHER,
        method: Methods.POST,
        data: data,
    })
}
