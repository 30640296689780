import React, { Fragment } from 'react';

import TransactionsTable from "./transactions";
import Filters from "./filters";
import {useTranslation} from "react-i18next";
import {WALLET_OWNER_TYPE, DATE_TIME_FORMAT} from "constants/common.constants";
import {USER_ROLE} from "constants/user.constants";
import {getUser} from "utils/auth";
import moment from "moment";
import useFormat from "hooks/useFormat";
import {makeTransactionText} from "components/common/transactions/heplers/makeTransactionText";

/** Transactions Component */
const Transactions = ({
    type
}) => {
    const { t } = useTranslation();
    const { formatNumber } = useFormat();

    /** Function for transaction mapping
     * @function
     * @param {object} transaction
     * @returns {object}
     * @memberOf Transactions
     */
    const getTransactionData = transaction => {
        const result = {
            player: "-",
            cashier: "-",
            betshop: "-"
        }
        const tr = transaction.transactions[0];
        if (!tr) return result;

        const transactionItems = tr.transactionItems;
        if (!transactionItems) return result;
        const playerTransaction = transactionItems.find(t => t.ownerType === WALLET_OWNER_TYPE.PLAYER);
        const cashierTransaction = transactionItems.find(t => t.ownerType === WALLET_OWNER_TYPE.CASHIER);
        const betshopTransaction = transactionItems.find(t => t.ownerType === WALLET_OWNER_TYPE.BETSHOP);

        if (playerTransaction) {
            result.player = playerTransaction.ownerName;
        }
        if (cashierTransaction) {
            result.cashier = cashierTransaction.ownerName;
        }
        if (betshopTransaction) {
            result.betshop = betshopTransaction.ownerName;
        }

        return result;
    }

    /** Table Columns */
    const columns = [
        {
            key: "transactionId",
            title: t("common.trasactionId"),
            render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{record?.transactions?.[0]?.transactionId}</span>,
            sorting: true
        },
        {
            key: "transactionTime",
            title: t("common.transactionDate"),
            render: value => <span className='rt--title rt--font-normal rt--font-regular'>{value ? moment.utc(value).local().format(DATE_TIME_FORMAT) : "-"}</span>,
            sorting: true
        },
        {
            key: "transactionType",
            title: t("common.transactionType"),
            render: value => (
                <span className='rt--title rt--font-normal rt--font-regular'>
                    {makeTransactionText(value, t)}
                </span>
            )
        },
        {
            key: "transactionAmount",
            title: t("common.amount"),
            render: value => <span className='rt--title rt--font-normal rt--font-regular'>{formatNumber(Math.abs(value))}</span>,
        },
        {
            key: "currencyCode",
            title: t("common.currency"),
        },
        ...(
            getUser()?.role === USER_ROLE.MANAGER ? [
                {
                    key: "cashier",
                    title: t("common.byCashier"),
                    disable: true,
                    render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{getTransactionData(record).cashier}</span>
                },
                {
                    key: "betshop",
                    title: t("common.betshop"),
                    disable: true,
                    render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{getTransactionData(record).betshop}</span>
                }
            ] : []
        ),
        ...(
            type === WALLET_OWNER_TYPE.CASHIER ? [
                {
                    key: "player",
                    title: t("common.player"),
                    disable: true,
                    render: (value, record) => <span className='rt--title rt--font-normal rt--font-regular'>{getTransactionData(record).player}</span>
                }
            ] : []
        ),
    ]

    return (
        <Fragment>
            <Filters type={type} columns={columns} />
            <TransactionsTable type={type} columns={columns} />
        </Fragment>
    )
}

export default Transactions;
