import PropTypes from 'prop-types';

import { BET_STATE, BET_TYPE } from 'constants/bets.constants';
import { PROJECT_PROVIDER_TYPE } from 'constants/common.constants';

export default PropTypes.shape({
    amount: PropTypes.number,
    betTime: PropTypes.string,
    betType: PropTypes.oneOf(Object.values(BET_TYPE)),
    calculateTime: PropTypes.string,
    event: PropTypes.string,
    eventId: PropTypes.number,
    externalBetId: PropTypes.number,
    externalBetSlipId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),    
    externalPartnerId: PropTypes.string,
    factor: PropTypes.number,
    id: PropTypes.number,
    market: PropTypes.string,
    paidoutBy: PropTypes.string,
    paidoutTime: PropTypes.string,
    possibleWin: PropTypes.number,
    provider: PropTypes.oneOf(Object.values(PROJECT_PROVIDER_TYPE)),
    result: PropTypes.string,
    sportName: PropTypes.string,
    status: PropTypes.oneOf(Object.values(BET_STATE)),
    tournamentName: PropTypes.string,
    winning: PropTypes.number
})