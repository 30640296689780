import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';

import Providers from './providers';
import Balance from './balance';
import Cashier from './cashier';
import Player from './player';
import Actions from './actions';
import PlayerSelector from './playerSelector';

import { getUser } from 'utils/auth';

import { BETSHOP_BET_TYPE, ENVIRONMENT_TYPE } from 'constants/common.constants';

import userInfoType from 'types/userInfo.type';
import Voucher from "components/cashier/header/voucher";
import {isTerminalProject} from "utils/access";

/** Header component */
const Header = ({
	userInfo,
	playerInfo,
}) => {
	const canPlaceAnonymousBets = BETSHOP_BET_TYPE.ANONYMOUS === userInfo.bettingType;
	const playerNotSelectedYet = !Boolean(playerInfo)
	const showCashierAndPlayerSections = canPlaceAnonymousBets || playerNotSelectedYet;

	return (
		<div className='rt--cashier-header rt--flex'>
			<div className='rt--cashier-header-section'>
				<Providers/>
			</div>
			{showCashierAndPlayerSections && (
				<>
					<div className='rt--cashier-header-section'>
						<Player/>
					</div>
					<div className='rt--cashier-header-section'>
						<Cashier/>
					</div>
				</>
			)}
			<div className='rt--cashier-header-section rt--flex-equal' data-action="finance">
				<Balance/>
			</div>

			<div className="rt--flex rt--mr-8">
				{isTerminalProject(userInfo.projectType) && (
					<div className='rt--cashier-header-section'>
						<Voucher/>
					</div>
				)}

				{(getUser()?.environmentType === ENVIRONMENT_TYPE.RETAIL && userInfo.bettingType !== undefined && !canPlaceAnonymousBets) && (
					<div className='rt--cashier-header-section'>
						<PlayerSelector/>
					</div>
				)}
			</div>
			<div className='rt--cashier-header-section rt--pr-24'>
				<Actions/>
			</div>
		</div>
	)
}

Header.propTypes = {
	/** Redux state property, current user info */
	userInfo: userInfoType,
	playerInfo: PropTypes.object
}

const mapStateToProps = state => {
	return {
		userInfo: state.profile.userInfo,
		playerInfo: state.profile.playerInfo,
	}
}

export default connect(mapStateToProps, null)(Header);
