export const VOUCHER_GENERAL_DETAILS = {
    BETSHOP_NAME: 1,
    CASHIER_USERNAME: 2,
    TERMINAL_NAME: 4,
    VOUCHER_ID: 8,
}

export const VOUCHER_INFO_DETAILS = {
    VOUCHER_CODE: 1,
    VOUCHER_AMOUNT: 2,
    PAYOUT_DATE: 4,
    EXPIRATION_DATE: 8,
}
